export const searchFormElements = () => {
    var qsParameters = window.location.search.substr(1).split('&');

    var qs = (function (a) {
        if (a === '') return {};
        var b = {};
        for (var i = 0; i < a.length; ++i) {
            var p = a[i].split('=', 2);
            b[p[0]] = p.length === 1 ? '' : decodeURIComponent(p[1].replace(/\+/g, ' '));
        }
        return b;
    })(qsParameters);

    //TODO - move these input related scripts to its own module
    var createPseudoCheckbox = function () {
        $('.dfes-checkbox').after(['<span class="dfes-pseudo-checkbox" ></span>'].join(''));
        var $parentCheckbox = $('.dfes-checkbox');
        $parentCheckbox.each(function () {
            setPseudoCheckbox($(this));
        });
    };

    var setPseudoCheckbox = function ($parentCheckbox) {
        var $pseudoCheckbox = $parentCheckbox.parent().find('.dfes-pseudo-checkbox');
        $parentCheckbox.is(':checked') ? $pseudoCheckbox.addClass('checked') : $pseudoCheckbox.removeClass('checked');
        $parentCheckbox.is(':focus') ? $pseudoCheckbox.addClass('focused') : $pseudoCheckbox.removeClass('focused');
    };

    var loadQSValues = function () {
        if (window.location.search.length > 0 && $('.search-main-container').length > 0) {
            if (!(typeof qs['searchQuery'] === 'undefined')) {
                //Hide content except for the search if search query string found
                $('.content-inner-body')
                    .children()
                    .not('.search-main-container')
                    .not('.search-results_container')
                    .not('.related-page-links')
                    .not('.accordion')
                    .addClass('has-searchquery');
            }
            if (!(typeof qs['titleOnly'] === 'undefined') && qs['titleOnly'] === 'true') {
                var $parentCheckbox = $($('[data-sf-role="titleOnlyCheckboxId"]').val());
                $parentCheckbox.prop('checked', true);
                setPseudoCheckbox($parentCheckbox);
            }
        }
    };

    // Generic type multiple select field
    var setTypeSelectField = function () {
        $('[data-custom-select-value]').each(function (el) {
            const $el = $(this);
            const selectedValue = $el.data('custom-select-value');

            if (typeof selectedValue !== 'undefined' && selectedValue.length > 0) {
                const splitValues = selectedValue.split(',');

                $.each(splitValues, function (ii, e) {
                    const $op = $el.find('option[value="' + e + '"]');
                    $op.attr('selected', 'selected');
                });
            }
        });
    };

    // Set select types
    setTypeSelectField();

    // Style multiple select boxes
    $('.u-select-multiple').multipleSelect();

    // Style checkboxes, hide html checkboxes
    createPseudoCheckbox();

    // Capture checkbox change event
    $('.dfes-checkbox').on('change focus blur', function () {
        setPseudoCheckbox($(this));
    });

    // Enter key
    $('.dfes-checkbox-label').on('keyup', function () {
        if (event.keyCode === 13) {
            $(this).click();
        }
    });

    // Load QS parameters
    loadQSValues();

    function searchHubFormDocs() {

        $(window).resize(function() {
            if ( window.innerWidth < 1200 ) {
                $('body').removeClass('search-modal-active');
                $('.search-modal').removeClass('active').hide();
                $('.search-modal-backdrop').hide();
            }
        });

        // search-modal close
        $(document).on('click', '.search-modal__dismiss', function() {
            $('body').removeClass('search-modal-active');
            $(this).closest('.search-modal').removeClass('active').fadeOut('fast', function() {
                $('.search-modal-backdrop').fadeOut('fast');
                $('.search-modal__dismiss').removeClass('is-focused');
            });
        });

        $(document).keyup(function(e) {
            if (e.keyCode === 27) {
                // Dismiss the modal here
                $('body').removeClass('search-modal-active');
                $('.search-modal').removeClass('active').fadeOut('fast', function() {
                    $('.search-modal-backdrop').fadeOut('fast');
                    $('.search-modal__dismiss').removeClass('is-focused');
                });
            }
        });

        // search modal open
        $(document).on('click', '#search-button-modal', function() {
            var searcModal = $('.search-modal');
            $('body').addClass('search-modal-active');
            $('.search-modal-backdrop').show();
            searcModal.addClass('active').fadeIn('fast');
            // searcModal.find('#txtGeneric_SearchBox_modal').focus();
            searcModal.focus();
            $('.search-modal__dismiss').removeClass('is-focused');
        });

        // search-modal tabs
        $(document).on('click', '.search-modal__tabs button', function() {
            var $this = $(this);
            var target = '.s-tab-content#' + $this.attr('data-target');
            // var results = '#' + $this.attr('data-target') + '-results';
            var searcModal = $('.search-modal');

            $this.siblings('button').removeClass('active');
            $this.addClass('active');
            $(target).siblings('.s-tab-content').hide();
            $(target).show();

            if ( $this.attr('data-target') == 'hubpages' ) {
                searcModal.find('#txtGeneric_SearchBox_modal').focus();
            } else {
                searcModal.find('#txtGeneric_SearchBox_modal-formdocs').focus();
            }
        });

        $(document).on('click', '.search-landing-tabs button', function() {
            var $this = $(this);
            var src = $this.attr('data-src');
            var url = new URL(window.location.href)

            if ( url.search != '' && url.searchParams.get('src') != '' ) {
                if ( url.searchParams.get('src') !== src ) {
                    url.searchParams.set('src', src);

                    if ( src === 'formsdocslanding' ) {
                        url.pathname = '/home/forms-documents';
                    } else {
                        url.pathname = '/home/search-results';
                    }
                }
            } else {
                if ( src === 'formsdocslanding' ) {
                    url.pathname = '/home/forms-documents';
                } else {
                    url.pathname = '/home/search-results';
                }
            }

            window.location.href = url.href;
        });

        $(document).on('click', '.search-main__mobile-tabs button', function(e) {
            e.preventDefault();
            var $this = $(this);
            var id = $this.attr('data-target');
            var searchMobile = $('.search-main__mobile');

            $this.addClass('active').siblings('button').removeClass('active');
            $('#'+id).show().siblings('.s-tab-content').hide();
            $('.sf-autocomplete').hide();

            if ( id == 's_pages' ) {
                searchMobile.find('#txtGeneric_SearchBox').focus();
            } else {
                searchMobile.find('#txtFormsDocs_SearchBox2').focus();
            }
        });

        // Modal trigger dropdowns on focus
        var detectDoneBtn = false;
        $("input, textarea, select, button").on("focus", function() {
            if ( !$('.search-modal').is(':visible') ) return;

            var $this = $(this);
            if ( $this.hasClass('select-multiple-type') ) {
                $this.next('.select-multiple-type').find('> button.js-btn-toggle').trigger('click');
            }
            
            
            if ( $this.closest('.ms-drop.bottom').is(':visible')) {
                if ( $this.is(':focus') && $this.hasClass('ms-select-all-close') ) {
                    detectDoneBtn = true;
                } else {
                    detectDoneBtn = false;
                }
            } else {
                // detection if element still a dropdown
                if ( detectDoneBtn && !$this.hasClass('select-multiple-type') ) {
                    $('.js-btn-toggle').attr('aria-expanded', true).next('.ms-drop.bottom').hide();
                }
                detectDoneBtn = false;
            }

            if ( $this.hasClass('search-modal__dismiss') ) {
                $this.addClass('is-focused');
            } else {
                $('.search-modal__dismiss').removeClass('is-focused').blur();
            }
            
        });
        // last link item in modal footer focusOut
        $('.last-link-focus').on('blur', function() {
            $('.search-modal__dismiss').focus();
        });

        $(document).on('keydown', function(e) {
            if (e.keyCode === 9 && $('.search-modal').hasClass('active') ) {
                if ( $('.search-modal-backdrop').has(e.target).length == 0 ) {
                    $('.search-modal__dismiss').focus();
                    e.preventDefault();
                }
            }
        });

        // handling auto-submission after hitting enter while tabs are focused
        $(document).keydown(function(e){
            if ( !$('.search-modal').hasClass('active') ) return;
            
            if ( e.keyCode == 13 ) {
                if ( $(event.target).data('target') ) { // tabs
                    var id = '#' + $(event.target).data('target');
                    $(event.target).addClass('active').siblings().removeClass('active');
                    $(id).show().siblings('.s-tab-content').hide();
                } else {
                    return;
                }

                e.preventDefault();
                return false;
            }
        });
        
    }

    searchHubFormDocs();
};
