import Cookies from 'js-cookie';
import { stringToBoolean } from '../helpers/helpers';

export function accessibility() {
    //button toggle to switch aria-pressed labels

    $(document).on('click keypress', '.js-btn-toggle', function (e) {
        if (e.which === 1 || e.which === 13) {
            const $this = $(this);

            e = e || window.event;

            if ($this.attr('aria-expanded') == 'true') {
                $this.removeAttr('aria-expanded');
            } else {
                $this.attr('aria-expanded', 'true');
            }
        }
    });

    const $sbButton = $('#btn-sidebar-toggle');
    const $sbContainer = $('#mp-menu');
    const strIsCollapsed = Cookies.get('sidebarCollapsed') !== 'true' ? 'false' : Cookies.get('sidebarCollapsed');
    var isCollapsed = stringToBoolean(strIsCollapsed);

    //if tabbing on sidebar toggle, set focus to sidebar menu if its open
    $sbButton.keydown(function (e) {
        if (!isCollapsed && e.which === 9) {
            //re-enable tab index for toolbox items
            $sbContainer.find('a, button').attr('tabindex', '0');

            e.preventDefault();
            $sbContainer.find('a').eq(0).focus();
        }
    });

    //when tabbing on last item in sidebar, set focus to first tabbable item in main content
    $sbContainer
        .find('button')
        .last()
        .keydown(function (e) {
            if (e.which === 9) {
                e.preventDefault();
                $('.content-inner-header').find('a, button').eq(0).focus();

                //temporarily set tabindex to -1 so that this section is skipped from the normal tab index
                $sbContainer.find('a, button').attr('tabindex', '-1');
            }
        });
}
