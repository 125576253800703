import { displayErrorMessage } from '../helpers/displayError';
import { formsNavigationField } from './forms-navigation-field';
import { formsPageBreak } from './forms-page-break';
import gtm from './gtm.js';
import { isNullUndefinedEmpty } from '../helpers/helpers';
import userStateManager from '../helpers/stateManager';

let parsley;

export async function forms() {
    //As label is input/button, handle selected file indicator rename

    let obj = await import('parsleyjs');
    parsley = obj.default;

    formsPageBreak();
    formsNavigationField();

    $(document).on('change focus blur', '.sf-FileField-input', handleFileInputs);

    populateAutoProfileFields();
    populateLoginStatus();
    checkboxSelectAll();

    function handleFileInputs(e) {
        const $el = $(e.target);
        const labelDefault = 'Upload file';
        var fileName;
        //Get file name
        if (e.target.files) {
            fileName = e.target.value.split('\\').pop();
        }

        //Check if blank filename (none selected)
        var newLabel = fileName.length > 0 ? fileName : labelDefault;
        $el.parent('label')[0].childNodes[2].nodeValue = newLabel;

        $el.is(':focus') ? $el.parent('label').addClass('focused') : $el.parent('label').removeClass('focused');
    }

    $('.form form').each(function () {
        const $form = $(this);

        $form
            .parsley()
            .on('field:error', function () {
                //Handle selectboxit
                const invalidSelects = $('select.parsley-error');
                const invalidCheckboxesRadios = $('.checkbox .parsley-errors-list, .radio .parsley-errors-list');
                if (invalidSelects.length > 0) {
                    invalidSelects.each(function () {
                        const errorList = $(this).siblings('.parsley-errors-list');
                        const selectBoxIt = $(this);

                        if (errorList.length > 0 && selectBoxIt.length > 0) {
                            selectBoxIt.parent().append(errorList);
                            selectBoxIt.addClass('parsley-error');
                        }

                        invalidSelects.siblings('.parsley-errors-list');
                    });
                }
                if (invalidCheckboxesRadios.length > 0) {
                    invalidCheckboxesRadios.each(function () {
                        const $fieldset = $(this).parents('fieldset');
                        $fieldset.append($(this));
                    });
                }
                $form.find('[type="submit"]').prop('disabled', false);
                setTimeout(() => {}, 500);
            })
            .on('form:validate', function (formInstance) {
                if (formInstance.isValid()) {
                    const eventData = {
                        event: 'gtm.formComplete',
                        formName: $('h1').text(),
                    };
                    gtm.pushEvent(eventData);
                }
            });

        const $requiredFields = $form.find('input[required], textarea[required], select[required]');
        $requiredFields.attr('aria-required', 'true');
        $requiredFields.parents('.form-group').addClass('-required');
        const $customInputs = $form.find('input[type="radio"], input[type="checkbox"]');
        $form.find('input:radio:checked').parents('.radio').addClass('is-checked');

        $customInputs.on('change focus blur', customRadioCheckboxesChange);

        const $selectboxes = $form.find('select');
        const selectTimeout = window.location.hostname.indexOf('-dev') > -1 ? 1000 : 0; //Wait 1s in dev watch mode for CSS to load
        $selectboxes.each(function () {
            $(this).change(function () {
                const parsleyError = $(this).parent().find('.parsley-error');
                const errorList = $(this).parent().find('.parsley-errors-list');

                errorList.remove();
                parsleyError.removeClass('parsley-error');
            });
        });
    });

    window.Parsley.on('field:error', function () {
        // This global callback will be called for any field that fails validation.
        // Check for pattern fields and update the parsley message to the SF message
        var $el = this.$element;

        if ($el.parent().find('[data-sf-role="violation-messages"]').length > 0) {
            var sfMessages = $el.parent().find('[data-sf-role="violation-messages"]').val();
            var sfMessagesObj = JSON.parse(sfMessages);

            //Required message from SF
            var $msgRequired = $el.parent().find('.parsley-required');
            if ($msgRequired.length > 0 && sfMessagesObj.required !== undefined) {
                $msgRequired.text(sfMessagesObj.required);
            }

            //Pattern message from SF
            var $msgPattern = $el.parent().find('.parsley-pattern');
            if ($msgPattern.length > 0 && sfMessagesObj.regularExpression !== undefined) {
                $msgPattern.text(sfMessagesObj.regularExpression);
            }
        }
    });

    function customRadioCheckboxesChange(e) {
        const $input = $(e.target);

        const $radio = $input.parents('.radio');
        const $checkbox = $input.parents('.checkbox');

        //Reset for radios
        if ($input.is('input[type="radio"]')) $input.parents('.form-group').find('.radio').removeClass('is-checked');

        //Checked state?
        $input.prop('checked') ? $radio.add($checkbox).addClass('is-checked') : $radio.add($checkbox).removeClass('is-checked');

        //Focus?
        $input.is(':focus') ? $radio.add($checkbox).addClass('is-focused') : $radio.add($checkbox).removeClass('is-focused');
    }

    function populateAutoProfileFields() {
        //Handle auto prefilling profile fields via FE to avoid caching issues in SF
        const $autoProfileField = $('[data-auto-profile-field]');
        if ($autoProfileField.length) {
            userStateManager.getUserProfileData(function (data) {
                if (data && !isNullUndefinedEmpty(data.ProfileInfo)) {
                    try {
                        $autoProfileField.each(function () {
                            const $el = $(this);
                            const fieldType = $el.data('auto-profile-field');
                            //Add values for each auto profile field value that can be rendered in the view
                            switch (fieldType.toLowerCase()) {
                                case 'name':
                                    $el.val(data.ProfileInfo.FirstName + ' ' + data.ProfileInfo.LastName);
                                    break;
                                case 'firstname':
                                    $el.val(data.ProfileInfo.FirstName);
                                    break;
                                case 'lastname':
                                    $el.val(data.ProfileInfo.LastName);
                                    break;
                                case 'email':
                                    $el.val(data.ProfileInfo.PersonalEmail);
                                    break;
                                case 'volunteerno':
                                    $el.val(data.ProfileInfo.VolunteerNo);
                                    break;
                            }
                        });
                    } catch (error) {
                        console.error('There was an issue prefilling user profile inputs: ', error);
                    }
                }
            });
        }
    }

    function populateLoginStatus() {
        $('[data-sf-role="login-status-button"]').on('click', function () {
            if ($('[data-sf-role="sf-allow-windows-sts-login"]').val().toLowerCase() === 'true') {
                location.href = '?stsLogin=true';
            } else {
                location.href = $('[data-sf-role="sf-login-redirect-url"]').val() || '#';
            }
            return false;
        });

        var loggedInView = $('[data-sf-role="sf-logged-in-view"]');
        var loggedInViewBtn = loggedInView.find('#dfes-sign-out-dropdown');
        var spinner = $('<div class="loader-wrapper"><div class="loader-2 loader-2--reverse">Loading...</div></div>');
        var el = $('[data-sf-role="sf-is-design-mode-value"]');

        loggedInViewBtn.hide();
        loggedInViewBtn.after(spinner);

        if (!isNullUndefinedEmpty(el) && !isNullUndefinedEmpty(el.val())) {
            if (el.val().toLowerCase() !== 'true') {
                userStateManager.getUserProfileData(function (data) {
                    if (data && data.apiProblemOccured) {
                        const errorMessageContainer = loggedInView.find('[data-sf-role="sf-logged-in-name"]');
                        displayErrorMessage(errorMessageContainer, true);
                        spinner.remove();
                        loggedInViewBtn.fadeIn();
                        return;
                    }

                    if (data && !isNullUndefinedEmpty(data.ProfileInfo)) {
                        try {
                            var displayName = '';
                            var emailAddr = '';
                            var avatarUrl = ''; //statusViewModel.AvatarImageUrl //Not in use...

                            if (!isNullUndefinedEmpty(data.ProfileInfo.FirstName)) {
                                displayName = data.ProfileInfo.FirstName;
                            }
                            if (!isNullUndefinedEmpty(data.ProfileInfo.PersonalEmail)) {
                                emailAddr = data.ProfileInfo.PersonalEmail;
                            }

                            loggedInView.find('[data-sf-role="sf-logged-in-avatar"]').attr('src', avatarUrl).attr('alt', displayName);
                            loggedInView.find('[data-sf-role="sf-logged-in-name"]').html(displayName);
                            loggedInView.find('[data-sf-role="sf-logged-in-email"]').html(emailAddr);
                            spinner.remove();
                            loggedInViewBtn.fadeIn();
                        } catch (error) {
                            console.error('There was an issue prefilling user profile inputs: ', error);
                        }
                    }
                });
            }
        }
    }

    function checkboxSelectAll() {
        const $checkboxList = $('.form').find('ul');
        const $checkboxes = $checkboxList.find('input[type="checkbox"]').not(':disabled');
        const $chkSelectAll = $('#chkSelectAll');

        $checkboxes.on('change', function () {
            if ($checkboxList.find('input:checked').length === $checkboxes.length) {
                $chkSelectAll.prop('checked', true);
            } else {
                $chkSelectAll.prop('checked', false);
            }
        });

        $chkSelectAll.on('click', function () {
            var $this = $(this);

            if ($this.prop('checked')) {
                $checkboxes.prop('checked', true);
            } else {
                $checkboxes.prop('checked', false);
            }
        });
    }
}
