export function svgInliner() {
    $('img.svg').each(function () {
        inlineSvgs(this);
    });
}

export function inlineSvgs(img) {
    var $img = $(img);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    var isColour = $img.hasClass('svg--respect-colors');

    $.get(
        imgURL,
        function (data) {
            var svg = $(data).find('svg');
            var paths = svg.find('path');
            var viewBox = svg.attr('viewBox');

            var div =
                $img.parents('button').length > 0
                    ? document.createElementNS('http://www.w3.org/1999/xhtml', 'span')
                    : document.createElementNS('http://www.w3.org/1999/xhtml', 'span');

            var pathsHTML = '';
            paths.each(function () {
                if ($(this).attr('fill') !== undefined && $(this).attr('fill').length > 0 && isColour) {
                    pathsHTML += '<path d="' + $(this).attr('d') + '" fill="' + $(this).attr('fill') + '"></path>';
                } else {
                    pathsHTML += '<path d="' + $(this).attr('d') + '" fill="#fff"></path>';
                }
            });

            if (isColour) {
                div.className = 'inline-svg inline-svg--colour';
            } else {
                div.className = 'inline-svg';
            }

            div.setAttribute('aria-hidden', true);
            div.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="' + viewBox + '">' + pathsHTML + '</svg>';

            $img.replaceWith(div);
        },
        'xml'
    );
}
