//Enforce pars on SF's bad text editor
export function enforceParagraphs() {
  $("[data-enforce-paragraphs]").each(function () {
    const $this = $(this);

    if ($this.children().length === 0) {
      $this.html("<p>" + $this.text() + "</p>");
    }
  });
}
