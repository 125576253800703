import { debounce } from '../helpers/helpers';

export function tables() {
    var responsiveWrap = $('<div/>', {
        class: 'u-table-container--responsive',
    });

    var updateTables = function () {
        $('.content-inner .content-block table').addClass('dfes-o-table dfes-o-table--unformatted');
    };

    var responsiveTables = function () {
        //TODO - rework to cover AJAX tables better?
        const $responsiveTables = $('.dfes-o-table').not('.u-table-container--responsive .dfes-o-table');
        $responsiveTables.wrap(responsiveWrap);
    };

    var handleSwipeDisplayResize = debounce(function () {
        checkTableSwipeDisplay();
    }, 50);

    updateTables();
    responsiveTables();
    checkTableSwipeDisplay();

    //Allow 2 seconds for AJAX tables as a catch all. Use exported function optionally for better timing accuracy.
    setTimeout(() => {
        checkTableSwipeDisplay();
    }, 2000);

    //Event listeners - accordion expand and window resize
    $(window).on('shown.bs.collapse', checkTableSwipeDisplay);
    window.addEventListener('resize', handleSwipeDisplayResize);
}

export function checkTableSwipeDisplay() {
    //Handle showing and hiding swipe based on overflow

    $('.u-table-container--responsive, .dataTables_wrapper').each(function () {
        if ($(this)[0].offsetWidth < $(this)[0].scrollWidth && $('html').hasClass('touchevents')) {
            //Add in if missing (covers AJAX)
            var $swipe = $(this).find('.table-swipe');
            if ($swipe.length === 0) {
                $swipe = $('<div class="table-swipe" style="display: none;"><span>Swipe to see more</span></div>');
                $(this).prepend($swipe);
            }

            $swipe.width($(this).find('table').innerWidth() + 2); //account for 1px borders
            $swipe.slideDown();
        } else {
            $(this).find('.table-swipe').slideUp();
        }
    });
}
