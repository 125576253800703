import { debounce } from '../helpers/helpers';

export function backToTop() {
    var $button = $('.back-to-top');

    //Debounce listener
    var debouncedCheck = debounce(function () {
        updatePosition();
    }, 10);
    window.addEventListener('scroll', debouncedCheck);

    function updatePosition() {
        var footerPosition = $('.footer').offset().top;
        var scrollPos = $(window).scrollTop();
        var windowHeight = window.innerHeight;
        var shouldBeFixed = scrollPos + windowHeight < footerPosition || window.innerWidth < 1200 ? true : false;

        if (shouldBeFixed) {
            $button.addClass('back-to-top--fixed');
        } else {
            $button.removeClass('back-to-top--fixed');
        }
        if (scrollPos < 900) {
            $button.addClass('back-to-top--hidden');
        } else {
            $button.removeClass('back-to-top--hidden');
        }
    }

    //Run once on load
    updatePosition();
}
