/**
 * jquery.utilities.js v1.0.0
 *
 */

(function ($) {
    var newUtilityExtension = {
        format: function () {
            var s = arguments[0];
            for (var i = 0; i < arguments.length - 1; i++) {
                var reg = new RegExp("\\{" + i + "\\}", "gm");
                s = s.replace(reg, arguments[i + 1]);
            }
            return s;
        },
        getBreakpoint : function () {
            return window.getComputedStyle(document.querySelector("body"), ":before").getPropertyValue("content").replace(/\"/g, "");
        },
        setClass: function (element, cssClassBefore, cssClass) {
            var preCssClass = cssClassBefore || "";
            var className = preCssClass + cssClass;
            $(element).addClass(className);
            return true;
        },
        clearClass: function (element, cssClassBefore, cssClass) {
            var preCssClass = cssClassBefore || "";
            var className = preCssClass + cssClass;
            $(element).removeClass(className);
            return true;
        }
    }
    $.extend(newUtilityExtension);
})(jQuery);