/*
 * Helper for pushing events to Google Tag Manager containers
 *
 * Usage:
 *
 * import gtm from '../site/js/gtm'; (adjust path accordingly)
 * gtm.pushEvent({
 *	event: 'gtm.formComplete', (use this standard notation for all event names)
 *	...
 * });
 *
 */

let gtm = gtm || {};

gtm = {
  init: function () {
    window.dataLayer = window.dataLayer || [];
  },

  /*
   * Expects an object with at least the 'event' property
   */
  pushEvent: function (data) {
    window.dataLayer.push(data);

    console.info("gtm push event - ", data);
  },
};

gtm.init();

export default gtm;
