export function calloutBoxes() {
  //TODO: session tracking?

  $(".callout-box").each(function () {
    const $callout = $(this);
    const $close = $(this).find(".btn-close");

    $close.on("click", function () {
      $callout.animate({ opacity: 0 });

      setTimeout(() => {
        $callout.slideUp();
      }, 400);
    });
  });
}
