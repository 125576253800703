import GameObject from './_gameObject';

export default class ScrollingObject extends GameObject {
    
    update() {
        super.update();
        if (this.position.x > this.gameController.settings.width) {
            this.destroy();
        } else {
            this.position.x = Math.round(this.position.x + this.velocity * this.gameController.state.gamespeed);
        }
    }
}