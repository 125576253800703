import { accessibility } from "../common/accessibility";
import { backToTop } from "../common/backToTop";
import { breakpoints } from "../common/breakpoints"; //FE TODO - potential refactor away from body class breakpoints
import { calloutBoxes } from "../common/calloutBoxes";
import { contactsSearch } from "../common/contactsSearch";
import { enforceParagraphs } from "../common/enforceParagraphs";
import { forms } from "../common/forms";
import { gtmDimensions } from "../common/gtmDimensions";
import { homeUnauthorised } from "../common/homeUnauthorised";
import { logging } from "../common/logging";
import { logout } from "../common/logout";
import { mainMenu } from "../common/mainMenu";
import { mobileMenu } from "../common/mobileMenu"; //FE TODO - potential refactor away from body class breakpoints
import { mobileSearch } from "../common/mobileSearch";
import { myProfileName } from "../common/myProfileName";
import { pageAnchors } from "../common/pageAnchors";
import { smoothScroll } from "../common/smoothScroll";
import { svgInliner } from "../common/svgInliner";
import { tabOrder } from "../common/tabOrder";
import { tables } from "../common/tables";
import { updateProfile } from "../common/updateProfile";
import { viewHeightVar } from "../common/viewHeightVar";

export const common = () => {
  logging(); //needs to be logged first
  accessibility();
  breakpoints();
  mainMenu();
  mobileMenu();
  mobileSearch();
  backToTop();
  tables();
  viewHeightVar();
  svgInliner();
  pageAnchors();
  tabOrder();
  smoothScroll();
  enforceParagraphs();
  calloutBoxes();
  logout();
  updateProfile();
  myProfileName();
  homeUnauthorised();
  contactsSearch();
  gtmDimensions();
  forms();
};
