import ScrollingObject from './_scrollingObject';

export default class Tree extends ScrollingObject {
    constructor(gameController) {
        super(gameController, 'tree', 1.4);
        this.position = {
            x: -100,
            y: 300
        };
        this.size = {
            x: 60,
            y: 100
        };
    }
}