export function pageAnchors() {
    var pageAnchors = {
        header: {
            anchor: '<a name="header"></a>',
            firstClass: '.header-inner',
        },
        footer: {
            anchor: '<a name="footer"></a>',
            firstClass: '.footer-inner',
        },
    };
    var setPageAnchors = function () {
        $.each(pageAnchors, function (k, v) {
            $(k).find(v.firstClass).first().before(v.anchor);
        });
    };

    setPageAnchors();
}
