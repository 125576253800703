import { isNullUndefinedEmpty } from "../helpers/helpers";
import userStateManager from "../helpers/stateManager";

//Render profile name in welcome message

export function myProfileName() {
  const $welcomeMessage = $(".welcome-message");

  if ($welcomeMessage.length) {
    userStateManager.getUserProfileData(function (data) {
      if (data && !isNullUndefinedEmpty(data.ProfileInfo)) {
        try {
          $welcomeMessage.find(".name").text(data.ProfileInfo.FirstName);
        } catch (error) {
          console.error(
            "There was an issue prefilling user profile inputs: ",
            error
          );
        }
      }
    });
  }
}
