export function mobileSearch() {
    const $searchContainer = $('.search-menu');
    const $searchToggle = $('.mobile-search-link, .search-menu--mobile-close');
    const $autocomplete = $('.sf-autocomplete');

    function toggleSearchMenu() {
        $searchContainer.animate(
            {
                width: ['toggle'],
            },
            {
                duration: 'fast',
            },
            $('.search-menu').width() - 50
        );
        
        const posTop = $searchContainer.position().top + 'px';
        const maxHeight = 'calc(100% - '+posTop+')';
        $searchContainer.css('max-height', maxHeight);

        $('.sf-autocomplete').toggleClass('-expanded-mobile'); //EQU
        $('body').toggleClass('-active-search-drawer');

        $searchContainer.toggleClass('--open');
        if ( $('.search-menu').hasClass('--open') ) {
            if ( $('#s_pages').is(':visible') ) {
                $searchContainer.find('#txtGeneric_SearchBox').focus();
                return;
            }
            
            if ( $('#s_forms-docs').is(':visible') ) {
                $searchContainer.find('#txtFormsDocs_SearchBox2').focus();

                return;
            }
        }       
    }

    $searchToggle.on('click', function (e) {
        e.preventDefault();
        toggleSearchMenu();

        mobileSearchFocus();
    });

    const mobileSearchFocus = () => {
        if ( window.outerWidth >= 1200 && !$('.search-menu').hasClass(' --open') ) return;

        $('#txtFormsDocs_SearchBox2').attr('tabindex', 5);
        $('#btnlbllstFormsDocs_CircularType2').attr('tabindex', 6);
        $('#btnlbllstFormsDocs_ServiceType2').attr('tabindex', 7);
        $('#btnlbllstFormsDocs_TopicType2').attr('tabindex', 8);
        $('#btnlbllstFormsDocs_RegionType2').attr('tabindex', 9);

        $(document).on('keydown', function(e) {
            if (e.key !== 'Tab') return;

            if ( $('#sm-tab-pages').hasClass('sm-is-focused') || $('#sm-tab-pages').hasClass('sm-is-focused') ) {
                $('#sm-tab-pages').removeClass('sm-is-focused').focus();
                return;
            }

            if ( $('#search-menu--mobile-close').is(':focus') ) {
                $('#sm-tab-docs').removeClass('active');
                $('#s_forms-docs').hide();
                $('#s_pages').show();
                $('#sm-tab-pages').addClass('active sm-is-focused');
            }

            else if ( $('#cmdGeneric_Search').is(':focus') ) {
                $('#sm-tab-pages').removeClass('active');
                $('#s_pages').hide();
                $('#sm-tab-docs').addClass('active');
                $('#s_forms-docs').show();
            }
        });
    };
    
}
