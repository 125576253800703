export const newsDetail = () => {
    var $mainTitle = $('.content-inner-header-title');
    var $newsTitle = $('.newsdetail-title');
    var $searchBox = $('.search-main-container');

    var setNewsTitle = function (newsTitle, mainTitle) {
        if (newsTitle !== '' && mainTitle !== newsTitle) {
            $mainTitle.html(newsTitle);
        }
    };

    var showSearch = function () {
        $searchBox.show();
    };

    var displayNewsArticle = function () {
        var getHash = window.location.hash.substr(1);
        if (getHash !== 'news-article') {
            showSearch();
        }
    };

    var setTitle = function () {
        var mainTitle = $mainTitle.text() || '';
        var newsTitle = $newsTitle.text() || '';

        if ($('.news-detail-landing-card').length > 0) {
            $('.u-news-heading').hide();
            $newsTitle = $('.content-inner-header-breadcrumbs .active').text();
            setNewsTitle($newsTitle, mainTitle);
        } else {
            setNewsTitle(newsTitle, mainTitle);
        }
    };

    displayNewsArticle();
    setTitle();
};
