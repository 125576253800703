import browserDetection from "../../vendor/browserDetection";

import Truck from './gameobjects/truck';
import Tree from './gameobjects/tree';
import Bush from './gameobjects/bush';
import Fire from './gameobjects/fire';
import Water from './gameobjects/water';
import {randomMinMax} from '../../helpers/helpers';

export default class VolunteerGame {
    constructor() {
        //this.wrapper = document.createElement('div').setAttribute('id','game-wrapper');
        this.gameController = {
            canvas: document.createElement('canvas'),
            childObjects: [],
			playerObject: null,
			livesObject: null,
            settings: {
                width: 1000,
                height: 600,
                gamespeed: 3.5,
                groundlevel: 400,
				gravity: 1.8
            },
            state: {
				running: false,
				startedTimeStamp: null, //Use our own timestamp as animation frame timestamp starts when dom loads
                jumpKeyHeld: false,
                jumpReleased: true,
				lives: 3,
				score: 0,
				firesJumped: 0,
				gamespeed: 3.5,
				level: 1
            },
            loop: null
		}
		
		this.$startButton = $('<button type="button" class="start">Start</button>');
		this.$closeButton = $('<button type="button" class="btn-close">Close</button>');

        this.animationController = {
            currentFrame: null, //Animation frame reference so we can canel animation frame loop
        }

        this.spawnController = {
            bush: {
                minDelay: 400,
                maxDelay: 1500,
                lastAddedTimestamp: 0,
                nextDelay: 0,
                max: 25,
                lastSpawnedObj: null,
            },
            tree: {
                minDelay: 0,
                maxDelay: 4000,
                lastAddedTimestamp: 0,
                nextDelay: 0,
                max: 10,
                lastSpawnedObj: null,
            },
            fire: {
                minDelay: 1700,
                maxDelay: 4000,
                lastAddedTimestamp: 0,
                nextDelay: 0,
                max: 3,
                lastSpawnedObj: null,
            }
        }

        this.init();
    }

    init() {
		this.beforeGameStart();
		this.preloadImages();
	}

	preloadImages(){
		
		//IE Support, also swaps to PNG for IE
		var usePng = false;
        var data = browserDetection();
		
		if (data.browser === "ie") {
			usePng = true;
		}
		
		preload('truck_body');
		preload('truck_wheel');
		preload('truck_siren');
		preload('water');
		preload('bush');
		preload('tree');
		preload('fire');
		
		function preload(svgFilename){
			var img = document.createElement('img');
			img.setAttribute('id',svgFilename);

			if (usePng) {
				img.src = `/assets/static/images/game/png/${svgFilename}.png`;
			} else {
				img.src = `/assets/static/images/game/${svgFilename}.svg`;
			}
			
			document.getElementsByClassName('game-wrapper')[0].appendChild(img);
		}
	}

	beforeGameStart() {
        $('html').addClass('hide-scroll');
        $('body').append(this.gameController.canvas);
		$(this.gameController.canvas).wrap('<div class="game-wrapper">');
		$(this.gameController.canvas).after(this.$startButton);
		$(this.gameController.canvas).after(this.$closeButton);


        //Set up canvas
        var context = this.gameController.canvas.getContext('2d');

        this.gameController.canvas.width = this.gameController.settings.width;
        this.gameController.canvas.height = this.gameController.settings.height;

        //Controls
        $(document).on('keydown', (ev) => {
            //Jump
            if (ev.which === 32) {
                this.gameController.state.jumpKeyHeld = true;
            }
            if (ev.which === 27) {
                this.close();
            }
        });

        $(document).on('keyup', (ev) => {
            //Jump
            if (ev.which === 32) {
                this.gameController.state.jumpKeyHeld = false;
                this.gameController.state.jumpReleased = true;
                this.gameController.playerObject.onJumpRelease();
            }
		});
		
		this.$closeButton.click(this.close);
		this.$startButton.click(this.start);
	}
	
	start = (ev) => {
		this.$startButton.hide();
		
		this.gameController.state.running = true;
		this.gameController.state.startedTimeStamp = + Date.now();
		
		//If the game has not been started before, create player
		if (this.animationController.currentFrame === null) {
			this.gameController.playerObject = new Truck(this.gameController);
			this.gameController.childObjects.push(new Water(this.gameController));
			
			this.animationController.currentFrame = requestAnimationFrame(this.gameUpdate);
		}
	}

	end(){
		this.$startButton.show();
		this.$startButton.text('Play again');
		this.$startButton.addClass('play-again');
		this.reset();
		this.gameController.state.running = false;
	}

	reset() {
		this.gameController.state.lives = 3;
		this.gameController.state.score = 0;
		this.gameController.state.firesJumped = 0;
		this.gameController.childObjects = [];
		this.gameController.childObjects.push(new Water(this.gameController));
		this.gameController.playerObject.position.x = this.gameController.settings.width;

		for (const key in this.spawnController) {
			if (this.spawnController.hasOwnProperty(key)) {
				const element = this.spawnController[key];
				element.lastSpawnedObj = null;
				element.lastAddedTimestamp = 0;
			}
		}
	}

    close = () => {
        cancelAnimationFrame(this.animationController.currentFrame);
        $('html').removeClass('hide-scroll');
        $('.game-wrapper').remove();
    }

    gameUpdate = () => {
		if (this.gameController.state.running) {
			const timestamp = + Date.now() - this.gameController.state.startedTimeStamp; 

			this.update(timestamp);
			this.draw(timestamp);
		}

        this.animationController.currentFrame = requestAnimationFrame(this.gameUpdate);

    }

    runSpawner(GameObjectClass, spawnerType, timestamp) {
        var activeCount = this.gameController.childObjects.filter(obj => { return obj instanceof GameObjectClass }).length; //&& obj.created + 1000 < Date.now()

        var blockedByPreviousSpawn = false;
        //Check the previously spawned object has moved out of the way - this catches odd issues on load where time progresses but we haven't had time to render
        if (this.spawnController[spawnerType].lastSpawnedObj !== null) {
            var lastSpawnedX = this.spawnController[spawnerType].lastSpawnedObj.position.x;
             
            if (lastSpawnedX < 0) {
				blockedByPreviousSpawn = true
            };
		}
		
        if (activeCount < this.spawnController[spawnerType].max &&
            (timestamp > this.spawnController[spawnerType].lastAddedTimestamp + this.spawnController[spawnerType].nextDelay) &&
            !blockedByPreviousSpawn
        ) {
            var newSpawnedObject = new GameObjectClass(this.gameController);
            this.gameController.childObjects.push(newSpawnedObject);
            this.spawnController[spawnerType].lastSpawnedObj = newSpawnedObject;
            this.spawnController[spawnerType].lastAddedTimestamp = timestamp;
            this.spawnController[spawnerType].nextDelay = Math.floor(randomMinMax(
				this.spawnController[spawnerType].minDelay / this.gameController.state.gamespeed, 
				this.spawnController[spawnerType].maxDelay / this.gameController.state.gamespeed)
				);
        }
	}

    update(timestamp) {
        this.runSpawner(Bush, 'bush', timestamp);
		this.runSpawner(Tree, 'tree', timestamp);
		
		if (timestamp > 2000) {
			this.runSpawner(Fire, 'fire', timestamp);
		}

        //child object updates
        this.gameController.childObjects.forEach((item, index) => {
            if (item.destroyed === true) {
                this.gameController.childObjects.splice(index, 1);
            }
        });
 
        this.gameController.childObjects.forEach((item, index) => {
            item.update();
        });

		this.gameController.playerObject.update();

		this.gameController.state.score = Math.floor(timestamp / 1000);
		const halfMinutesPassed = Math.floor((timestamp / 1000) / 30);
		
		this.gameController.state.level = 1 + halfMinutesPassed;
		this.gameController.state.gamespeed = this.gameController.settings.gamespeed + halfMinutesPassed * 0.5;
    }

    draw(timestamp) {
        var ctx = this.gameController.canvas.getContext("2d");
        ctx.clearRect(0, 0, this.gameController.settings.width, this.gameController.settings.height);

        //Ground
        ctx.fillStyle = 'black';
        ctx.fillRect(0, this.gameController.settings.groundlevel, this.gameController.settings.width, 4);

        this.gameController.childObjects.forEach(item => {
            item.draw();
        });
		this.gameController.playerObject.draw();
		
		ctx.font = "bold 16px Arial";
		ctx.textAlign = "center";
		//Intro text
		
		if (timestamp < 2000) {
			ctx.fillText("Press Spacebar to jump the fire!", this.gameController.settings.width / 2, this.gameController.settings.height / 3)
		}

		//UI elements
		ctx.font = "bold 16px Arial";
		ctx.textAlign = "left";

		const scoreTime = `Time: ${this.gameController.state.score}`;
		ctx.fillText(scoreTime, 50, 50);
		
		const scoreJumps = `Score: ${this.gameController.state.firesJumped}`;
		ctx.fillText(scoreJumps, 50, 80);

		ctx.textAlign = "center";
		const levelText = `Level: ${this.gameController.state.level}`;
		ctx.fillText(levelText, this.gameController.settings.width / 2, 50);
		
		if (this.gameController.state.lives <= 0) {
			this.end();
		}
    }
}