import { poll } from "../helpers/helpers";

export function breakpoints() {
  var dfesBpId = "dfes-bp-";
  var setLastBreakpoint = function (breakpoint) {
    $("body").data("breakpoint", breakpoint);
    return $("body").data("breakpoint");
  };
  var getLastBreakpoint = function () {
    return $("body").data("breakpoint");
  };
  var breakpointChanged = function (currentBreakpoint) {
    var lastBreakpoint = getLastBreakpoint();
    return currentBreakpoint !== lastBreakpoint ? true : false;
  };
  $(window).on("resize", function () {
    var previousBreakpoint = getLastBreakpoint();
    var currentBreakpoint = $.getBreakpoint();
    if (breakpointChanged(currentBreakpoint)) {
      $.clearClass("body", dfesBpId, previousBreakpoint);
      $.setClass("body", dfesBpId, setLastBreakpoint(currentBreakpoint));
      $.setClass("body", dfesBpId, "changed");
    } else {
      $.clearClass("body", dfesBpId, "changed");
    }
  });

  //Issue with CSS is not rendering before JS is running.
  //Poll for the css :before breakpoint to be set
  //TODO: Remove all usage of css body:before breakpoint as it's a pretty redundant setup
  poll(
    function () {
      return $.getBreakpoint() !== "none";
    },
    5000,
    150
  ).then(function () {
    $.setClass("body", dfesBpId, setLastBreakpoint($.getBreakpoint()));
  });
}
