export default class userStateManager {
    constructor() {
        this.defs = null;
    }

    static setSubState(subStateObj, propKey, callback) {
        if (typeof subStateObj != "undefined" && subStateObj != null) {
            this.getUserState(function (responseObj) {

                if (responseObj != null) {
                    //fresh userStateObj...
                    var stateObj = responseObj;

                    //Update relevant props...
                    for (var prop in subStateObj) {
                        stateObj[propKey][prop] = subStateObj[prop];
                    }

                    //Push it back to the server:
                    this.saveUserState(stateObj,
                        function (respObj) {
                            if (typeof callback != "undefined" && callback != null) {
                                callback(respObj);
                            }
                        }.bind(this));
                }
            }.bind(this));
        }
    }
    static getEmptySubState(propKey) {
        if (this.defs != null && typeof this.defs[propKey] != "undefined" && this.defs[propKey] != null) {
            return this.constructNewObj(this.defs[propKey]);
        }
        else {
            console.log("No definition for " + propKey + " exists, perhaps run getUserState first.");
            return null;
        }
    }
    static constructNewObj(srcObj) {
        var retVal = {};
        for (var prop in srcObj) {
            retVal[prop] = null;
        }
        return retVal;
    }

    static getUserState(callback) {
        this.getStateDataHandler(callback, "GetUserState", "userState");
    }

    static getUserProfileData(callback) {
        this.getStateDataHandler(callback, "GetUserProfileData", "profileData");
    }
    /**
     * Get a user state based on a key and API endpoint. Will check session storage before making an API call.
     * @param {function} callback 
     * @param {string} endpoint 
     * @param {string} stateKey 
     */
    static getStateDataHandler(callback, endpoint, stateKey) {
        //The window variable should always be updated
        //And should trigger an event to ensure all components update
        var stateValue = sessionStorage.getItem(stateKey);
        if (stateValue !== null && typeof stateValue !== 'undefined' && stateValue !== 'undefined') {
            //console.log('getStateData was called for ' + stateKey + ' and existing data was returned. No call made.')
            const stateValueObj = JSON.parse(stateValue);
            
            if (stateKey === 'profileData') {
                //Catch edge case of a previous login with no profile data (CMS user) switching to volunteer or staff
                //Also don't cache where there is an API issue
                if (stateValueObj.ProfileInfo !== null && !stateValueObj.apiProblemOccured) {
                    callback(stateValueObj);
                } else {
                    this.stateAPICaller(callback, endpoint, stateKey);
                }
            } else {
                callback(stateValueObj);
            }
        }
        else {
            this.stateAPICaller(callback, endpoint, stateKey)
        }
    }

    static stateAPICaller(callback, endpoint, stateKey, pollCount = 0) {
        var that = this;

        jQuery.ajax({
            type: "GET",
            url: "/dfes-api/UserAPI/" + endpoint,
            success: function (response, status) {

                //Handle polling for profile data
                //Poll occurs for profileData, if the API returned a non sitefinity user, but the data has not been supplied by VisAPI yet
                var noProfileData = 
                    stateKey === 'profileData' && 
                    typeof response.data !== 'undefined' && 
                    typeof response.data.ProfileInfo !== 'undefined' && 
                    (response.data.ProfileInfo === null || (response.data.FromVISAPI == false && response.data.ProfileInfo.DFESUserType !== 'sitefinityUser' ));
                
                    //if (stateKey === 'profileData') debugger;

                if (noProfileData && pollCount < 10) {
                    var newPollCount = pollCount + 1;
                    console.log('Sitefinity user returned was ', response.data.ProfileInfo);
                    console.log('Waiting 1s for profileData poll as the API can take time. New poll count is ', pollCount);
                    setTimeout(() => {
                        that.stateAPICaller(callback, endpoint, stateKey, newPollCount);
                    }, 1000);
                    
                } else if (noProfileData && pollCount >= 10){
                    console.warn(`profileData expected VisAPI values as this user is a ${response.data.ProfileInfo.DFESUserType} but no values could be retrieved after 10s. `)
					response.data.apiProblemOccured = true;
					finaliseAPICall(response);
                }
                else {
                    finaliseAPICall(response);
                }
            },
            dataType: "json"
        });

        //Helper method to finalise call
        function finaliseAPICall(response) {
            that.defs = response.defs;
            sessionStorage.setItem(stateKey, JSON.stringify(response.data));
            if (typeof callback != "undefined" && callback != null) {
                callback(response.data);
            }
        }
    }

    static getAllToolboxItems(callback) {

        jQuery.ajax({
            type: "GET",
            url: "/dfes-api/ToolboxAPI/GetAllToolboxItems",
            success: function (response, status) {
                //Set any state dataObj defs here, so we can create new instances...
                this.defs = response.defs;
                if (typeof callback != "undefined" && callback != null) {
                    callback(response);
                }
            }.bind(this),
            dataType: "json"
        });
    }

    static clearUserState(callback) {
        jQuery.ajax({
            type: "GET",
            url: "/dfes-api/UserAPI/ClearUserState",
            success: function (response, status) {
                //Set any state dataObj defs here, so we can create new instances...
                if (typeof callback != "undefined" && callback != null) {
                    callback(response);
                }
            }.bind(this),
            dataType: "json"
        });
    }

    static saveUserState(stateObj, callback) {
        //The window variable should always be updated
        //And should trigger an event to ensure all components update

        sessionStorage.setItem('userState', JSON.stringify(stateObj));

        const userStateUpdated = new CustomEvent('userStateUpdated');
        window.dispatchEvent(userStateUpdated);

        if (typeof stateObj != "undefined" && stateObj != null) {
            var svcUrl = "/dfes-api/UserAPI/SetUserState";
            var stateData = JSON.stringify(stateObj);
            var postParams = "data=" + stateData;

            jQuery.post(svcUrl,
                postParams,
                function (response, status) {
                    if (typeof callback != "undefined" && callback != null) {
                        callback(response);
                    }
                }.bind(this),
                "json");
        }
    }


    static getContactsByRegion(region, callback) {

        jQuery.ajax({
            type: "GET",
            url: `/dfes-api/ContactsAPI/GetContactsByRegion?region=${region.toLowerCase().replace(/ /g, "")}`,
            success: function (response, status) {
                //console.log(`Get contacts by region "${region}" response: `, response);
                callback(response.data);
            }.bind(this),
            dataType: "json"
        });
    }
}