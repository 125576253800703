const loadedComponents = [];

const Load = async (obj) => {
    // Gets props and renders component
    var data = obj.getAttribute('data-js-inject');

    if (!loadedComponents.includes(data)) {
        loadedComponents.push(data);

        console.info('injected component', data);

        const { component } = await import('./' + data);
        component();
    }
};

const dynamicLoad = async () => {
    //check for all data-js-inject
    var toInject = document.querySelectorAll('[data-js-inject]');

    for (var component of toInject) {
        Load(component);
    }
};

export const DynamicLoader = async () => {
    await dynamicLoad();
};
