export function tabs() {
    var resizeTimer;

    // If mobile view add accordion container +
    var addAccordionContainer = function () {
        $('.tab-pane').each(function (i, v) {
            var thisId = $(this).attr('id');
            var thisTitle = $("a[href*='" + thisId + "']").text();
            var thisNewId = 'collapse' + i;
            var thisPanelTitle = $.format(
                '<div class="panel-heading"><h4 class="panel-title"><a data-toggle="collapse" class="collapsed" aria-expanded="false" data-parent=".tab-pane" href="#{0}">{1}</a></h4></div>',
                thisNewId,
                thisTitle
            );

            const collapseClass = 'panel-collapse collapse';

            $(this).wrapInner(
                $('<div/>', {
                    class: 'panel panel-default',
                }).prepend(
                    $('<div/>', {
                        class: collapseClass,
                        id: thisNewId,
                    }).prepend(
                        $('<div/>', {
                            class: 'panel-body',
                        })
                    )
                )
            );
            $(this).find('.panel-default').prepend(thisPanelTitle);
        });
    };

    function resetAccordionResize() {
        if ($('body').hasClass('dfes-bp-changed')) {
            $('.tab-pane').each(function (index) {
                const $panel = $(this).find('.panel-collapse');

                $panel.addClass('in');
                $panel.removeAttr('style');
                if (window.innerWidth < 992) {
                    $panel.removeClass('in');
                }
            });
        }
    }

    $(function () {
        // Augment the existing tabs with accordions
        addAccordionContainer();

        //Fix the bad random MVC controls from having the wrong aria tag
        $('.sfs-tabstrip-configurator > li > a').each(function (index) {
            $(this).removeAttr('aria-controls');
        });

        window.addEventListener('resize', resetAccordionResize);
        resetAccordionResize();
    });
}
