export function mainMenu() {
    $(window).on('resize', function () {
        if ($.getBreakpoint().indexOf('desktop') > -1) {
            $('.cbp-hrsub-inner').matchHeight();
            $.fn.matchHeight._update();
        }
    });

    $('.cbp-hrmenu').cbpHorizontalMenu();
    $('.menu-main_list-item-link:contains("/")').addClass('u-menu-link-lg');
    $('.cbp-hrsub-inner').matchHeight();
}
