import { LoadScript } from './helpers.js';
import { siteInit } from '../entry';

export const oldBrowser = () => {
    $('body').append(`
        <div id="panelOldBrowser" class="update-browser u-flex u-flex-justify">
            <div>
                <div class="logos">
                    <div class="logo logo-govwa">
                        <img id="dfes-svg-logo-wa-gov" class="dfes-svg-logo-wa-gov" src="/assets/static/images/wagov.svg" alt="Government of Western Australia">
                    </div>
                    <div class="logo logo-dfes">
                        <img id="dfes-svg-logo" class="dfes-svg-logo" src="/assets/static/images/dfes.svg" alt="Department of Fire and Emergency Services">
                    </div>
                    <div class="logo logo-volunteerhub">
                        <a href="/" class="print-url-hidden">Volunteer<span class="u-logo">Hub</span></a>
                    </div>
                </div>
                <h2>Improve your experience</h2>
                <p>You're using an unsupported web browser.<br>We recommend you use an up-to-date version of one of these browsers:</p>
                <ul class="u-list-reset u-flex u-flex-justify">
                    <li>
                        <a href="https://www.google.com/chrome/">
                            <img src="/assets/static/images/browserIcons/chrome.png" class="u-block-element" alt>
                            Chrome
                        </a>
                    </li>
                    <li>
                        <a href="https://www.mozilla.org/en-US/firefox/new/">
                            <img src="/assets/static/images/browserIcons/firefox.png" class="u-block-element" alt>
                            Firefox
                        </a>
                    </li>
                    <li>
                        <a href="https://www.microsoft.com/en-us/edge">
                            <img src="/assets/static/images/browserIcons/edge.png" class="u-block-element" alt>
                            Edge
                        </a>
                    </li>
                </ul>
                <button id="btnOldBrowser" class="btn">Continue anyway</button>
            </div>
        </div>
    `);

    $('#btnOldBrowser').on('click', function () {
        sessionStorage.setItem('ignoreOldBrowser', true);
        $('#panelOldBrowser').remove();

        loadIE11();
    });
};

export const loadIE11 = () => {
    // If current browser doesn't support all features, load polyfill bundle
    LoadScript(window.location.origin + '/assets/build/ie11-bundle.js', () => {
        siteInit();
    });
};
