var jQuery = require('jquery');
var jq = jQuery.noConflict(true);

import 'regenerator-runtime/runtime';
import './vendor/bootstrap-3.3.7/transition';
import './vendor/bootstrap-3.3.7/dropdown';
import './vendor/bootstrap-3.3.7/collapse';
import './vendor/bootstrap-3.3.7/tab';
import './vendor/bootstrap-3.3.7/modal';
import './vendor/bootstrap-3.3.7/tooltip';
import './vendor/bootstrap-3.3.7/popover';
import './vendor/jquery.mainMenu';
import './vendor/jquery.columnizer';
import './vendor/jquery.dlmenu';
import './vendor/jquery.utilities';
import './vendor/jquery.multipleselect';
import './vendor/jquery.matchHeight';
import './vendor/jquery-ui';

import { BrowserSupportsAllFeatures, LoadScript } from './helpers/helpers.js';
import { loadIE11, oldBrowser } from './helpers/old-browser';

import { DynamicLoader } from './components/dynamic-helper.js';
import { accordion } from './components/accordion';
import browserDetection from './vendor/browserDetection';
import { common } from './components/common';
import { konami } from './components/konami/index';
import { newsDetail } from './components/newsDetail';
import { onThisPage } from './components/onthispage';
import { relatedLinks } from './components/relatedLinks';
import { searchFormElements } from './components/searchFormElements';
import { tabs } from './components/tabs';

export const siteInit = () => {
    common();
    DynamicLoader();
    accordion();
    tabs();
    newsDetail();
    searchFormElements();
    onThisPage();
    //keep below on this page
    relatedLinks();
    konami();

    //lazy load react
    if ($('.js-react-comp').length > 0) {
        LoadScript(window.location.origin + $('#reactBundleJs').attr('href'), () => {});
    }
};

(function ($) {
    if (BrowserSupportsAllFeatures()) {
        var data = browserDetection();
        $('html').addClass($.format('u-browser-{0}', data.browser));
        if (data.browser === 'ie') {
            if (data.version <= 11) {
                $('html').addClass($.format('u-browser-{0}-{1}', data.browser, data.version));
            }
        }
        siteInit();
    } else if (sessionStorage.getItem('ignoreOldBrowser')) {
        loadIE11();
    } else {
        oldBrowser();
    }
})(jq);
