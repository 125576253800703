export const onThisPagePush = (title, id) => {
    const $onthispage = $('#on-this-page');
    const $onthispageContainer = $('.on-this-page');
    var $item = $('<li>');

    $item.append('<a class="on-this-page__link u-node-equal-heights" href="#' + id + '">' + title + '</a>');

    if ($onthispage.length > 0) {
        $onthispage.append($item);
    }

    $onthispageContainer.addClass('u-hidden-mobile');
};

export const onThisPage = () => {
    //Trigger expanding accordion on clicking 'on this page'

    $(document).on('click', '#on-this-page a', function (ev) {
        const href = $(this).attr('href');

        if ($(href).length > 0) {
            const expanded = $(href).attr('aria-expanded');
            if (expanded === 'false') {
                $(href).parents('.accordion').find('.accordion__header').click();
            }
        }
    });
};
