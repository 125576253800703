import ScrollingObject from './_scrollingObject';
import {randomMinMax} from '../../../helpers/helpers';

export default class Bush extends ScrollingObject {
    constructor(gameController) {
        super(gameController, 'bush', 2.5);
        this.position = {
            x: -100,
            y: Math.floor(randomMinMax(420, 500))
        }
        this.size = {
            x: 58,
            y: 26
        }
        this.velocity = 2 + ((this.position.y - 420) * 0.01); //Velocity based on how close it is
        // this.scale = 1 + ((this.position.y - 430) * 0.005);
        // this.el.css('z-index', this.position.y);
        // this.el.css('transform', `scale(${this.scale})`);
	}

	// draw() {
    //     var ctx = this.gameController.canvas.getContext('2d');
	// 	ctx.drawImage(this.el, this.position.x, this.position.y, this.size.x, this.size.y);
		
	// 	ctx.font = "bold 16px Arial";
	// 	ctx.textAlign = "center";
	// 	ctx.fillText(this.velocity, this.position.x + 10, this.position.y + 40);
    // }
}