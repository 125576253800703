import GameObject from './_gameObject';

export default class Water extends GameObject {
    constructor(gameController) {
        super(gameController, 'water', 0);
        this.position = {
            x: this.gameController.settings.width - 70,
            y: 50
        }
        this.size = {
            x: 22,
            y: 28
        }
	}
	draw(){
		var ctx = this.gameController.canvas.getContext('2d');
		
		//Render lives UI
		for (let i = 0; i < this.gameController.state.lives; i++){
			ctx.drawImage(this.el, this.position.x - (i * (this.size.x + 10)), this.position.y, this.size.x, this.size.y);
		}
	}
}