export async function logging() {
  const Sentry = await import("@sentry/browser");

  var settings = window.sentrySettings;
  if (settings == null) {
    console.log(
      "Sentry settings has not been initialised, please check you have the render"
    );
    return;
  }

  Sentry.init({
    dsn: settings.dsn,
    environment: settings.environment,
  });
}
