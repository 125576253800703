
//Renders update profile links via ajax to avoid sitefinity caching issues
import { isNullUndefinedEmpty } from '../helpers/helpers';

export function updateProfile() {
    const endpoint = `/dfes-api/UserAPI/GetUpdateProfileLink?returnUrl=${location.href}`;
    $('[data-update-profile-link]').click(function(){
        jQuery.ajax({
            type: "GET",
            url: endpoint,
            success: function (response, status) {
                if(!isNullUndefinedEmpty(response) && response.exitCode == 0)
                {
                    const link = response.data.UpdateProfileLink;
                    if (link !== null && typeof link !== 'undefined' && link.length > 0) {
                        window.document.location = link;
                    }else{
                        console.log("Update link api failed")
                    }
                }
            }.bind(this),
            dataType: "json"
        });
    });   
}