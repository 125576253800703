/**
 * Base GameObject
 */
export default class GameObject {
    /**
     * 
     * @param {Object} gameController - Reference to the main game controller
     * @param {String} imageSrc - String path to the image path that will be used in the canvas
     * @param {Number} velocity - Base velocity, relevant only to moving objects
     */
    constructor(gameController, imageId, velocity) {
        this.el = document.getElementById(imageId);        
        this.velocity = velocity;
        this.position = {
            x: 0,
            y: 0,
        };
        this.size = {
            x: 100,
            y: 100
        };
        this.gameController = gameController;
        this.created = Date.now();
		this.destroyed = false;
		this.loaded = false;
        this.init();
    }

	intersect(a, b) {
		return (a.position.x <= (b.position.x + b.size.x) &&
			b.position.x <= (a.position.x + a.size.x) &&
			a.position.y <= (b.position.y + b.size.y) &&
			b.position.y <= (a.position.y + a.size.y))
	}

	onLoaded = (ev) => {
		this.loaded = true;
	}

    init() {
		//check if image was already loaded by the browser
		
    }

    update() {
        this.customUpdate()
    }
    
    customUpdate(){

    }

    draw() {
			var ctx = this.gameController.canvas.getContext('2d');
			ctx.drawImage(this.el, this.position.x, this.position.y, this.size.x, this.size.y);
		
    }

    destroy() {
        this.destroyed = true;
    }
}