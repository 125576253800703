import VolunteerGame from './volunteerGame';

export function konami() {
	try {
		konamiLoader(function () {
			new VolunteerGame();
		});
	} catch (error) {
		console.warn('There was an issue with the konami', error);
	}
    
}

function konamiLoader(callback) {

    var keys = [];
    var ran = false;
    var lastKeyTime = Date.now();

    const konami = "38,38,40,40,37,39,37,39,66,65";

    $(document).keydown(function (e) {

        if (Date.now() > lastKeyTime + 2000) keys = [];

        lastKeyTime = Date.now();
        keys.push(e.keyCode);

        if (keys.toString().indexOf(konami) >= 0 && ran === false) {
            ran = true;
            callback();
        }
    });
}