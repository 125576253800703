import GameObject from './_gameObject';


export default class Truck extends GameObject {
	constructor(gameController) {
		super(gameController, 'truck_body', 1);
		this.baseLevel = this.gameController.settings.groundlevel - 66;
		this.position = {
			xAfterIntro: 750,
			x: this.gameController.settings.width,
			y: this.baseLevel
		};
		this.size = {
			x: 100,
			y: 57
		};
		this.velocity = 0;
		this.velocityIntro = 2;
		this.onGround = true;
		this.previousJumpReleased = true;   //Avoid holding space to continuously jump
		this.jumpTimer = new Date();
		this.maxJumpY = 250;
		this.initialJumpForce = 5;
		this.wheel = {
			position: this.position.y + 43,
			originOffset: 43,
			currentOffset: 0,
			velocity: -0.1,                  //Moves up first
			maxOffset: 3,                //Bump height
		}
		this.wheel1 = document.getElementById('truck_wheel');
		this.wheel2 = document.getElementById('truck_wheel');
		this.siren = document.getElementById('truck_siren')
		this.sirenOpacity = 0;
	}

	onJumpRelease() {
		this.previousJumpReleased = true;
	}

	update() {
		var jumpForce = 0;

		if (this.gameController.state.jumpKeyHeld) {

			if (this.onGround && this.previousJumpReleased) {
				//Player on ground and previous jump ended, begin new jump
				this.previousJumpReleased = false;
				this.onGround = false;
				this.jumpTimer = Date.now();
				jumpForce = this.initialJumpForce;

			} else if (!this.previousJumpReleased) {
				//Player is not on the ground, the key continues to be held, but check it hasn't already been released
				//Get the time so far, and calculate a reduction on the force
				const timePassedSeconds = (Date.now() - this.jumpTimer) / 1000;
				//If the max jump HEIGHT has not passed, reduce by factor of 10 force per second
				if (this.position.y > this.maxJumpY) {
					//Reduce by factor of 10 force per second
					jumpForce = this.initialJumpForce - ((timePassedSeconds) * 10)
				}
			}
		}

		//Calculate wheel position animation
		//Flip the wheel velocity if it's outside either upper or lower boundary
		this.wheel.currentOffset = this.wheel.currentOffset + (this.wheel.velocity * this.gameController.state.gamespeed);
		if (this.wheel.currentOffset < 0) {
			this.wheel.currentOffset = 0;
			this.wheel.velocity = this.wheel.velocity * -1;
		}
		if (this.wheel.currentOffset > this.wheel.maxOffset) {
			this.wheel.currentOffset = this.wheel.maxOffset;
			this.wheel.velocity = this.wheel.velocity * -1;
		}
		//Siren opacity, synchronised to wheel offset
		this.sirenOpacity = this.wheel.currentOffset / this.wheel.maxOffset;

		//Calc truck position
		this.velocity = this.velocity - jumpForce + this.gameController.settings.gravity;
		//Update positions, consider in a slight factor of wheel animation for a 'bump' affect
		this.position.y = this.position.y + (this.velocity / 1.3);

		//Calculate the intro 
		const timestamp = + Date.now() - this.gameController.state.startedTimeStamp;
		if (timestamp > 500) {
			if (this.position.x > this.position.xAfterIntro) {
				this.position.x += (this.position.xAfterIntro - this.position.x) / 50;
			}
		}

		//Reset if out of bounds/on ground and add wheel bump animation
		if (this.position.y > this.baseLevel) {
			this.onGround = true;
			this.velocity = 0
			this.position.y = this.baseLevel - (this.wheel.currentOffset / 2)
		}

		//Set wheel position
		this.wheel.position = this.position.y - this.wheel.currentOffset + this.wheel.originOffset;
	}

	//Custom truck draw 
	draw() {
		var ctx = this.gameController.canvas.getContext('2d');
		ctx.drawImage(this.el, this.position.x, this.position.y, this.size.x, this.size.y);

		//Wheel 1
		ctx.drawImage(this.wheel1, this.position.x + 8, this.wheel.position, 24, 24);

		//Wheel 2
		ctx.drawImage(this.wheel2, this.position.x + 67, this.wheel.position, 24, 24);

		ctx.save();
		ctx.globalAlpha = this.sirenOpacity;
		ctx.drawImage(this.siren, this.position.x + 8, this.position.y - 8, 20, 8)
		ctx.restore();
	}
}