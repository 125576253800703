///////////////////
//Debounce
///////////////////
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
// // Usage
// var myEfficientFn = debounce(function() {
// 	// All the taxing stuff you do
// }, 250);
// window.addEventListener('resize', myEfficientFn);

///////////////////
//Polling
///////////////////
//Sometimes you don't get to plug into an event to signify a desired state -- if the event doesn't exist, you need to check for your desired state at intervals
//Requires Promise (Polyfill for older IE)
export function poll(fn, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    var checkCondition = function (resolve, reject) {
        // If the condition is met, we're done!
        var result = fn();
        if (result) {
            resolve(result);
        }
        // If the condition isn't met but the timeout hasn't elapsed, go again
        else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        }
        // Didn't match and too much time, reject!
        else {
            reject(new Error('timed out for ' + fn + ': ' + arguments));
        }
    };

    return new Promise(checkCondition);
}
// // Usage
// poll(function() {
// 	//return document.getElementById('lightbox').offsetWidth > 0;
// }, 2000, 150).then(function() {
//     // Polling done, now do something else!
// }).catch(function() {
//     // Polling timed out, handle the error!
// });

///////////////////
//Ounce
///////////////////
//There are times when you prefer a given functionality only happen once, similar to the way you'd use an onload event.
//This code provides you said functionality
export function once(fn, context) {
    var result;

    return function () {
        if (fn) {
            result = fn.apply(context || this, arguments);
            fn = null;
        }

        return result;
    };
}
// // Usage
// var canOnlyFireOnce = once(function() {
// 	console.log('Fired!');
// });

// canOnlyFireOnce(); // "Fired!"
// canOnlyFireOnce(); // nada

///////////////////
//Insert CSS rules
///////////////////
//Great for AJAX heavy sites, better than inline styles
var sheet = (function () {
    // Create the <style> tag
    var style = document.createElement('style');

    // Add a media (and/or media query) here if you'd like!
    // style.setAttribute('media', 'screen')
    // style.setAttribute('media', 'only screen and (max-width : 1024px)')

    // WebKit hack :(
    style.appendChild(document.createTextNode(''));

    // Add the <style> element to the page
    document.head.appendChild(style);

    return style.sheet;
})();
// // Usage
// sheet.insertRule("header { float: left; opacity: 0.8; }", 1);

///////////////////
//Responsive helpers
///////////////////
export function getWindowWidth() {
    var windowWidth = $(window).width();
    return windowWidth;
}

export function getBreakpoints() {
    var breakpoints = {};
    breakpoints.mobile = 420;
    breakpoints.mobileLarge = 600;
    breakpoints.tablet = 768;
    breakpoints.desktop = 1024;
    breakpoints.desktopLarge = 1280;
    breakpoints.wide = 1500;

    return breakpoints;
}

export function animateTo($element, transitionTime, customOffset) {
    var headerOffset = 60;
    if (!transitionTime) {
        var transitionTime = 1000;
    }
    if (!customOffset) {
        var customOffset = 0;
    }

    $('html, body')
        .stop()
        .animate(
            {
                scrollTop: $element.offset().top - headerOffset + customOffset,
            },
            transitionTime,
            'easeInOutCubic'
        );
}

export const fadeSmoother = {
    fadeIn: function ($el) {
        $el.css('opacity', '0');
        $el.slideDown(400);

        setTimeout(() => {
            $el.animate({ opacity: 1 });
        }, 400);
    },
    fadeOut: function ($el) {
        $el.animate({ opacity: 0 });

        setTimeout(() => {
            $el.slideUp(400);
        }, 400);
    },
};

export function truncateToWord(str, maxLength = 100) {
    var returnVal = '';
    if (str !== null && typeof str !== 'undefined' && str.length > maxLength) {
        returnVal = str.substr(0, maxLength + 1);
        //re-trim if in the middle of a word and add elipsis
        returnVal = returnVal.substr(0, Math.min(returnVal.length, returnVal.lastIndexOf(' '))) + '...';
    } else {
        returnVal = str;
    }
    return returnVal;
}

export function isNullUndefinedEmpty(v) {
    if (v == '' || typeof v == 'undefined' || v == null || v.length === 0) return true;

    return false;
}

Object.defineProperty(String.prototype, 'toNormalisedName', {
    value: function toNormalisedName() {
        //if updating the regex be sure to update to match the urlNameRegex from the SitefinityHelper and NormalisedName from TaxonomyHelper
        var v = this;
        if (v == '' || typeof v == 'undefined' || v == null || v.length === 0) return v;
        v = v.replace(/[^\w\-\!\$\'\(\)\=\@\d_]+/g, '-');
        v = v.replace(/[\d-./]+/g, '');
        return v;
    },
    writable: true,
    configurable: true,
});

export function randomMinMax(min, max) {
    return Math.random() * (max - min + 1) + min;
}

export function randomMinMaxDecimal(min, max) {
    return randomMinMax(min, max).toFixed(2);
}
export function appendVal(dst, src, separator) {
    if (src && typeof src !== 'undefined' && src.length > 0) {
        dst = dst + src + separator;
    }
    return dst;
}

export const spinnerHtml = '<div class="loader-wrapper"><div class="loader-2 loader-2">Loading...</div></div>';
export const spinnerReverseHtml = '<div class="loader-wrapper"><div class="loader-2 loader-2--reverse">Loading...</div></div>';

export function getQueryString(field, url) {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1].replace(/\+|(%20)/g, ' ').replace(/%2C/g, ',') : '';
}

export const BrowserSupportsAllFeatures = () => {
    return window.Promise && window.fetch && window.Symbol;
};

export const LoadScript = (url, callback) => {
    var script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
        //IE
        script.onreadystatechange = function () {
            if (script.readyState == 'loaded' || script.readyState == 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        //Others
        script.onload = function () {
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

export function stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
        case null:
            return false;
        default:
            return Boolean(string);
    }
}
