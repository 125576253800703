import Cookies from "js-cookie";
import { isNullUndefinedEmpty } from "../helpers/helpers";
import userStateManager from "../helpers/stateManager";

export async function gtmDimensions() {
  const Sentry = await import("@sentry/browser");

  userStateManager.getUserProfileData(function (data) {
    //spit out richer data to sentry
    if (data && !isNullUndefinedEmpty(data.ProfileInfo)) {
      Sentry.setUser({
        email: data.ProfileInfo.PersonalEmail,
      });
      Sentry.setContext("Profile", data.ProfileInfo);
    }

    if (
      data &&
      !isNullUndefinedEmpty(data.ProfileInfo) &&
      !isNullUndefinedEmpty(data.ProfileInfo.BGUs)
    ) {
      const existingUserIdCookie = Cookies.get("UserId");
      const existingUserTypeCookie = Cookies.get("UserType");
      const existingServiceCookie = Cookies.get("ServiceSegment");
      const existingRegionsCookie = Cookies.get("RegionSegment");
      const existingLeaderCookie = Cookies.get("LeaderSegment");

      if (typeof existingUserIdCookie === "undefined") {
        Cookies.set("UserId", data.ProfileInfo.VolunteerNo);
        Sentry.setContext("UserId", data.ProfileInfo.VolunteerNo);
      }

      if (typeof existingUserTypeCookie === "undefined") {
        Cookies.set("UserType", data.ProfileInfo.DFESUserType);
        Sentry.setContext("UserType", data.ProfileInfo.DFESUserType);
      }

      if (typeof existingServiceCookie === "undefined") {
        var bguTypes = data.ProfileInfo.BGUs.map((item) => item.BguType);
        var uniqueBguTypes = bguTypes.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        var uniqueBguTypesSorted = uniqueBguTypes.sort();

        Cookies.set("ServiceSegment", uniqueBguTypesSorted.join(","));
        Sentry.setContext("ServiceSegment", uniqueBguTypesSorted.join(","));
      }

      if (typeof existingRegionsCookie === "undefined") {
        var bguRegions = data.ProfileInfo.BGUs.map((item) => item.Region);
        var uniqueBguRegions = bguRegions.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        var uniqueBguRegionsSorted = uniqueBguRegions.sort();

        Cookies.set("RegionSegment", uniqueBguRegionsSorted.join(","));
        Sentry.setContext("RegionSegment", uniqueBguRegionsSorted.join(","));
      }

      if (typeof existingLeaderCookie === "undefined") {
        var isCurrentlyBguLeader = data.ProfileInfo.ServiceHistory.some(
          (item) => {
            return item.IsLeader && item.EndDate === null;
          }
        );

        Cookies.set("LeaderSegment", isCurrentlyBguLeader);
        Sentry.setContext("LeaderSegment", isCurrentlyBguLeader);
      }
    }
  });
}
