import { debounce } from "../helpers/helpers";

export function viewHeightVar() {
  function setupViewHeightVar() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  setupViewHeightVar();

  //Catch watcher causing odd timing issue...
  setTimeout(() => {
    setupViewHeightVar();
  }, 5000);

  var viewHeightDebounce = debounce(function () {
    setupViewHeightVar();
  }, 50);
  window.addEventListener("resize", viewHeightDebounce);
}
