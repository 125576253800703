// Handle some HTML movements for correct tabindex
// Set up (legacy) jQuery menu plugins.
// Note the plugins have been customised to allow more flexibile markup

export function mobileMenu() {
    const $menu = $('.menu-main');
    const $myDetailMobile = $('.nav-wrapper--mydetail');
    const $myDetailTrigger = $('.dl-trigger--my-detail');
    const $logos = $('.logos');
    const $headerContainer = $('.header-inner > div[data-sf-element="Container"]');

    function moveMenuMobile() {
        $menu.detach();
        $logos.before($menu);
    }

    function moveMenuDesktop() {
        $menu.detach();
        $headerContainer.append($menu);
    }

    //Move my detail mobile dropdown for all breakpoints
    if ($myDetailMobile.length > 0) {
        $myDetailMobile.detach();
        $myDetailTrigger.after($myDetailMobile);
    }

    //Menu movements for correct tab index
    if ($menu.length > 0) {
        if ($(window).width() < 1200) {
            moveMenuMobile();
        }

        $(window).on('resize', function () {
            if ($(window).width() < 1200) {
                moveMenuMobile();
            } else {
                moveMenuDesktop();
            }
        });
    }

    $('.nav-wrapper--mobile').dlmenu({
        animationClasses: { classin: 'dl-animate-in-2', classout: 'dl-animate-out-2' },
        trigger: '.dl-trigger--menu',
    });
    $('.nav-wrapper--mydetail').dlmenu({
        animationClasses: { classin: 'dl-animate-in-2', classout: 'dl-animate-out-2' },
        trigger: '.dl-trigger--my-detail',
    });

    $('.dl-trigger--menu, .dl-mobile-menu-close').on('click', function () {
        if ( $(this).hasClass('dl-mobile-menu-close') && !$('html').hasClass('menu-open') ) return;

        $('.nav-wrapper--mydetail').dlmenu('closeMenu').removeClass('z-index-first');
        $('.nav-wrapper--mobile').removeClass('z-index-second').addClass('z-index-first');
    });

    $('.dl-mobile-menu-close').on('click', function() {
        if (!$('html').hasClass('menu-open')) return;
        
        $('html').removeClass('hide-scroll menu-open');
    })

    $('.dl-trigger--my-detail').on('click', function () {
        $('.nav-wrapper--mobile').dlmenu('closeMenu').removeClass('z-index-first');
        $('.nav-wrapper--mydetail').removeClass('z-index-second').addClass('z-index-first');
    });

    $('.dl-back').each(function () {
        var $subMenuItemLink = $(this).parent().siblings()[0];
        var $subMenuLink = $($(this).parent().siblings()[0]);
        var dataName = $subMenuItemLink.innerText;
        $(this).find('a:first')[0].innerText = dataName;
    });

    // My detail toolbox collapse/expanders.
    // OOTB bootstrap not flexible enough for accordion style (collapse siblings) outside of a .panel
    $('.dl-menu_link').on('click', function (ev) {
        if ($(ev.currentTarget).is('[aria-expanded="false"]')) {
            $('.dl-menu__toolbox-wrapper').collapse('hide');
        }
    });
}
