import ScrollingObject from './_scrollingObject';

export default class Fire extends ScrollingObject {
	constructor(gameController) {
		super(gameController, 'fire', 1.8);
		this.position = {
			x: -100,
			y: this.gameController.settings.groundlevel - 29
		};
		this.size = {
			x: 22,
			y: 29
		};
		this.hasScored = false;
	}

	update() {
		super.update();
		const collided = this.intersect(this.gameController.playerObject, this);
		const beyondPlayer = this.position.x > this.gameController.playerObject.position.x + this.gameController.playerObject.size.x + 20;

		//Count the fire as having been passed if it goes beyond the truck
		if (beyondPlayer && !this.hasScored) {
				this.gameController.state.firesJumped ++; 
				this.hasScored = true;
			}

		if (collided) {
			this.gameController.state.lives = this.gameController.state.lives - 1;
			this.destroy();
		}
	}
}