//Uses bootstrap collapse.js
//Converts AccordionTitle attribute to accordion title and ID for 'on this page' target

import { onThisPagePush } from './onthispage';

export const accordion = () => {
    $.each($('.accordion'), function () {
        const $btn = $(this).find('.accordion__header');
        const $collapse = $(this).find('.accordion__content > .sf_colsIn');
        const collapseId = $collapse.attr('id');
        const accordionTitle = $(this).find('.accordion-title').data('accordion-id');

        $btn.attr('data-toggle', 'collapse');
        $btn.attr('href', '#' + collapseId);
        $btn.addClass('collapsed');
        $btn.attr('aria-expanded', 'false');
        $collapse.attr('aria-expanded', 'false');
        $collapse.addClass('collapse');

        if (typeof accordionTitle !== 'undefined') {
            var btnId = accordionTitle.replace(/[, _+=!@#$%^&*(),*.;':"/?\\\$]/g, '-').replace(/-+/g, '-');
            $btn.attr('id', btnId);
            $btn.html('<h2>' + accordionTitle + '</h2>');

            onThisPagePush(accordionTitle, btnId);
        } else {
            console.warn('An accordion is missing an accordion title widget!');
        }
    });

    //Footer accordion and login - ensure accessibility is removed for desktop to avoid confusion
    if ($(window).width() > 1200) {
        $('.footer-contact_container').removeClass('collapse').removeAttr('aria-expanded');
        $('#login-collapse-wrap').removeAttr('aria-expanded');
    }

    //make all accordions expanded by default on My Profile & My BGU pages
    if ($('#my-bgu-details-wrapper').length > 0 || $('.my-profile').length > 0) {
        $('.accordion__header').each(function () {
            $(this).click();
        });
    }

    const hash = $(window.location.hash);
    const hashParentAccordion = hash.parents('.accordion');

    if (hashParentAccordion.length > 0) {
        const accordionBtn = hashParentAccordion.find('.accordion__header');
        accordionBtn.click();
        setTimeout(() => {
            const offset =
                window.location.hash.indexOf('Content_') > 0 //scroll to the accordion button if the hash is a sitefinity content placeholder
                    ? hashParentAccordion.offset().top
                    : hash.offset().top;
            $('html, body').animate(
                {
                    scrollTop: offset,
                },
                400
            );
        }, 1000);
    }
};
